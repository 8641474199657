/*!
 * Copyright 2019 the original author or authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */.tooltip-link{margin-left:5px}.twitter-typeahead{display:block !important}.typeahead,.tt-query{width:396px;height:30px;padding:8px 12px;border:2px solid #ccc;outline:none}.typeahead{background-color:#fff}.typeahead:focus{border:2px solid #0097cf}.tt-query{-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075);-moz-box-shadow:inset 0 1px 1px rgba(0,0,0,.075);box-shadow:inset 0 1px 1px rgba(0,0,0,.075)}.tt-hint{color:#999}.tt-menu{width:100%;margin:-1px 0;padding:8px 0;background-color:#fff;border:1px solid rgba(0,0,0,.2);border-top:1px dotted rgba(0,0,0,.2);-webkit-box-shadow:0 5px 10px rgba(0,0,0,.2);-moz-box-shadow:0 5px 10px rgba(0,0,0,.2);box-shadow:0 5px 10px rgba(0,0,0,.2)}.tt-suggestion{padding:3px 20px}.tt-suggestion:hover{cursor:pointer;color:#fff;background-color:#0097cf}.tt-suggestion.tt-cursor{color:#fff;background-color:#0097cf}.tt-suggestion p{margin:0}.nav.d-block{display:block}.nav.d-block .nav-item{display:inline-block}.list-group-subgroup-toggle:after{float:right;margin-top:.5em;display:inline-block;margin-left:.255em;vertical-align:.255em;content:"";border-top:0;border-right:.3em solid transparent;border-bottom:.3em solid;border-left:.3em solid transparent}.list-group-subgroup-toggle.collapsed:after{border-top:.3em solid;border-right:.3em solid transparent;border-bottom:0;border-left:.3em solid transparent}.card-header+.list-group>.list-group-subgroup>.list-group-item:first-child{border-top:1px solid rgba(0,0,0,.125)}.list-group-subgroup .list-group-item{padding-left:1.75rem}